import loadable from '@loadable/component'
import Typography from '@material-ui/core/Typography'
import { graphql, PageProps } from 'gatsby'
import parse from 'html-react-parser'
import React from 'react'

import { ArticleContainer } from '../components/PageBody/styles'
import Wrapper from '../components/UI/Wrapper'

const Layout = loadable(() => import('../components/Layout'))

const NotFoundPage = ({
  data: { allContentstackResourcesWebsite },
}: PageProps<Queries.FourOFourPageQuery>) => {
  const pageData = allContentstackResourcesWebsite.nodes[0]

  return (
    <Layout
      locale="en-us"
      slug="/404/"
      title={pageData.not_found_page_headline}
    >
      <Wrapper>
        <Typography
          color="primary"
          component="h1"
          style={{ margin: '96px 0 32px' }}
          variant="h2"
        >
          {pageData.not_found_page_headline}
        </Typography>
        <ArticleContainer>
          {parse(pageData.not_found_page_body ?? '')}
        </ArticleContainer>
      </Wrapper>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query FourOFourPage {
    allContentstackResourcesWebsite(
      filter: { publish_details: { locale: { eq: "en-us" } } }
    ) {
      nodes {
        not_found_page_headline
        not_found_page_body
      }
    }
  }
`
